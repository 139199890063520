import iziToast from 'izitoast'

export const ajaxManagerConfig = async () => {
  await $store.dispatch('ajaxManager/setService', [
    {
      name: 'AMS',
      config: {
        url: process.env.VUE_APP_AMS_SERVICE_URL,
        port: process.env.VUE_APP_AMS_SERVICE_PORT,
        apiVer: null,
        token: ''
      }
    },
    {
      name: 'SS',
      config: {
        url: process.env.VUE_APP_ARC_STORAGE_SERVICE_URL,
        port: process.env.VUE_APP_ARC_STORAGE_SERVICE_PORT,
        apiVer: null,
        token: ''
      }
    },
    {
      name: 'backend-service',
      config: {
        url: process.env.VUE_APP_VOIP_GENERAL_BACKEND_SERVICE_URL,
        port: process.env.VUE_APP_VOIP_GENERAL_BACKEND_SERVICE_PORT,
        /*url: 'http://127.6.1.1',
        port: '8000',*/
        apiVer: null,
        token: ''
      }
    },
  ])
  await $store.dispatch('ajaxManager/setErrorHandler', (error) => {
    if (error.response) {
      let errorCode = error.response.status
      if (error.response.status == 400) {
        if (error.response.data.errorMessage) {
          $store.state['App-App-state'].generalDialogStatus = true
          $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
          $store.state['App-App-state'].generalDialogTitle = "Attention"
        }
      }
      if (errorCode === 401) {

        $services['notify']['view']({
          type: 'warning',
          title: 'Caution',
          message: 'You have to check your credentials',
          position: 'center',
          timeout: 600,
          animateInside: true,
          close: false,
          onClosed: () => {
            $store.dispatch('App-User-state/clearUserData').then(() => {
              window.location = $router.resolve({ name: 'portal-home' }).resolved.fullPath
            })
          }
        })
      }
    } else {
      console.log('ajax manager Error Handler', error)
    }
  })
}
