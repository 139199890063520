let namePrefix = 'portal-cpanel'
export default [
    {
        path: '/portal',
        name: namePrefix,
        parent: '/',
        component: () => import('@/app/portals/cpanel/Main.vue'),
        redirect: {
            name: namePrefix + '->dashboard'
        },
        children: [
            {
                path: 'dashboard',
                name: namePrefix + '->dashboard',
                component: () => import('@/app/portals/cpanel/pages/Dashboard.vue'),
                meta: {
                    guards: {
                        'auth': {}
                    }
                },
            },
            /*{
                path: 'user',
                name: namePrefix + '->user',
                component: () => import('@/modules/App/User/View'),
                meta: {
                    guards: {
                        'auth': {}
                    }
                },
                children: [
                    {
                        path: 'logout',
                        name: namePrefix + '->user->logout',
                        component: () => import('@/modules/App/User/logout/View'),
                        meta: {
                            guards: {
                                'auth': {}
                            }
                        },
                    }
                ]
            }*/
        ]
    }
]
