import Vue from 'vue'
import {hkms} from 'devegram-vue2-hkms/src/hkms'
import store from '@/system-services/vuex/index'
import router from '@/system-services/router/index'
import notification from '@/system-services/notification/notify'
import {ajaxManagerConfig} from '@/system-services/ajax-manager/ajaxManagerConfig'
import {initUILang} from '@/system-services/language/index'
import {getLS} from "devegram-javascript-util";
import {AFD} from 'devegram-javascript-util'

// import helpers
import {mapActions, mapState} from "vuex"
import {listView, moduleForm} from 'devegram-vue-mixins/src/mixins'
import fieldController from 'devegram-vue-data-collecter-eui/src/mixins/fieldController';

const bootDefaultVuexModules = async () => {
    return new Promise(async function (resolve) {
        $hkms.load('App', {name: 'User', 'group_name': 'system', is_package: true, package_name: 'devegram-vue-user-module'}).then(async function () {
            await $hkms.load('App', {name: 'App', 'group_name': 'system', is_package: true, package_name: 'devegram-vue-app-module'})
            await $hkms.load('App', {name: 'SideMenu', 'group_name': 'system'})
            await $hkms.load('Office', {name: 'Insurance', 'group_name': 'system'})
            await $hkms.load('Office', {name: 'Software', 'group_name': 'system'})
            await $hkms.load('Office', {name: 'Supplier', 'group_name': 'system'})
            await $hkms.load('Office', {name: 'Inventory', 'group_name': 'system'})
            await $hkms.load('Accounting', {name: 'Asset', 'group_name': 'system'})
            await $hkms.load('Accounting', {name: 'Note', 'group_name': 'system'})
            await $hkms.load('App', {name: 'UiColorSchema', 'group_name': 'system'})

            // await $hkms.load('Accounting', {name: 'Client', 'group_name': 'system'})
            // await $hkms.load('Test', {name: 'Pokemon', 'group_name': 'system'})
            // await $hkms.load('side-nav')
            //await bootModules($store.state['App-User-state'].modules)
            resolve()
        })
    })
}

const $router = router
export const globalData = {
    '$vue': Vue,
    '$mapActions': mapActions,
    '$mapState': mapState,
    '$store': store(Vue),
    $router,
    '$hkms': hkms(),
    ...initUILang(),
    '$W_LOGGER_DEBUG': true,
    '$dbugLogger': function (...params) {
        if ($W_LOGGER_DEBUG) console.log(...params)
    },
    $xBundle: {},
    $services: {},
    $mixins: {},
    $xapi: {},
    $components: {}

}

export const bootUserPermissions = () => {
    return new Promise((resolve, reject) => {
        let user = getLS('user')
        if (user && user.modules) {
            $store.dispatch('App-User-api/verify', {
                onError: (error, resolve, reject) => {
                    resolve(false)
                }
            }).then(r => {
                let userBackendModules = user.modules
                let localModules = $store.state['App-User-state'].modules
                let useLocalModules = $store.state['App-User-state'].useLocalModules
                $hkms.bootNamespacesModules((useLocalModules) ? localModules : userBackendModules).then(() => {
                    $store.state['App-User-state'].isVerified = true
                    resolve(true)
                })
            })

        } else {
            resolve(false)
        }
    })
}


export const afterBoot = async (globalData) => {
    return new Promise(async function (resolve) {
        ajaxManagerConfig(globalData.$store)
        globalData.$hkms.store = globalData.$store
        globalData.$hkms.router = $router

        globalData.$xapi['$store'] = $store

        globalData.$mixins['listView'] = listView
        globalData.$mixins['fieldController'] = fieldController
        globalData.$mixins['moduleForm'] = moduleForm

        globalData.$services['AFD'] = AFD
        globalData.$services['notify'] = notification

        await bootDefaultVuexModules()
        await bootUserPermissions()
        await $store.dispatch('App-App-state/doneLoading')
        resolve()
    })
}

export default {globalData, afterBoot}
