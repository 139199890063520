const routes = [
    {
        path: '/shared',
        name: 'portal-shared',
        component: () => import('@/app/portals/shared/View.vue'),
        children: [
            {
                path: 'call-details',
                name: 'portal-shared-call-details',
                component: () => import('@/app/portals/shared/call-details/View.vue'),
                children: [
                    {
                        path: ':broadcastPublicId',
                        name: 'portal-shared-call-details-broadcast-public-id',
                        component: () => import('@/app/portals/shared/call-details/broadcast-cdr-view/View.vue'),
                    }
                ]
            },
        ]
    }
]

export default routes
