<template>
  <div>
    <Dialog :title="generalDialogTitle" :visible.sync="generalDialogStatus" @close="$store.state['App-App-state'].generalDialogStatus = false" center
            width="420px">
      <div align="center" class="mb-3 p-3" style="word-break: break-word; font-size: 11pt"
           v-html="generalDialogMessage"></div>
    </Dialog>

    <Dialog :visible.sync="questionDialogStatus" center width="420px" :append-to-body="true" @close="$store.state['App-App-state'].questionDialogStatus = false">
      <div align="center" class="mb-2" v-html="questionDialogTitle"></div>
      <div align="center" class="mt-2 mb-2" v-html="questionDialogMessage"></div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="() => {$store.state['App-App-state'].questionDialogStatus = false; $store.state['App-App-state'].questionDialogOnCancel()}" size="small">Cancel</el-button>
        <el-button @click="() => {$store.state['App-App-state'].questionDialogStatus = false; $store.state['App-App-state'].questionDialogOnConform(); questionDialogOnCancel()}" size="small" type="danger">Yes</el-button>
      </span>
    </Dialog>

    <Dialog :visible.sync="mediaDialogStatus" @close="$store.state['App-App-state'].mediaDialogStatus = false" center title="Media Viewer" top="5vh"
            width="90%">
      <div align="center">
        <img :src="mediaDialogFile.image" style="max-height: 50vh" v-if="mediaDialogStatus && mediaDialogFile.isImage">
        <div v-if="mediaDialogStatus && !mediaDialogFile.isImage">
          no file viewer available
        </div>
      </div>
    </Dialog>

    <loadingSpinner v-show="$store.state.ajaxManager.loadingRequests"></loadingSpinner>
    <router-view v-show="!$store.state['App-App-state'].isLoading" :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import 'pdfmake/build/vfs_fonts';
import {Dialog, Button} from 'element-ui'
import loadingSpinner from '@/components/LoadingSpinner.vue'
import {mapState} from 'vuex'
// import {defineAsyncComponent} from 'vue'

export default {
  computed: {
    ...mapState('ajaxManager', ['loadingRequests']),
    ...mapState('App-App-state', ['generalDialogStatus', 'generalDialogTitle', 'generalDialogMessage', 'mediaDialogStatus', 'mediaDialogFile']),
    ...mapState('App-App-state', ['questionDialogStatus', 'questionDialogTitle', 'questionDialogMessage', 'questionDialogOnConform', 'questionDialogOnCancel']),
  },
  components: {Dialog, loadingSpinner, 'el-button': Button},
  methods: {
    check_webp_feature(feature, callback) {
      var kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
      };
      var img = new Image();
      img.onload = () => {
        var result = (img.width > 0) && (img.height > 0);
        this.callback(feature, result);
      };
      img.onerror = () => {
        this.callback(feature, false);
      };
      img.src = "data:image/webp;base64," + kTestImages[feature];
    },
    callback (feature, status) {
      console.log('feature ' + feature, status)
    }
  },
  beforeMount() {
    /*this.check_webp_feature('lossy')
    this.check_webp_feature('lossless')
    this.check_webp_feature('alpha')
    this.check_webp_feature('animation')*/
    document.body.style.direction = this.$langConfig.dir;
    document.body.style.textAlign = this.$langConfig.align;
  }
}
</script>
