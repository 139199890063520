import Vue from 'vue'
import VueRouter from 'vue-router'
import portalHomeRoutes from '@/app/portals/home/routes.js'
import portalCpanelRoutes from '@/app/portals/cpanel/routes.js'
import portalSharedRoutes from '@/app/portals/shared/routes.js'
import {checkRouteGuards} from '@/system-services/middleware/guards/main.js';
import {documentInfo} from '@/system-services/middleware/documentInfo.js';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'root',
        redirect: {
            name: 'portal-home'
        },
        meta: {
            documentInfo: {
                pageTitle: "Page Title Here",
            },
            /*guards: {
              'auth': {}
            }*/
        }
    },
    {
        path: '*',
        redirect: '/',
        meta: {
            /*guards: {
              'auth': {}
            }*/
        }
    },
    ...portalHomeRoutes,
    ...portalCpanelRoutes,
    ...portalSharedRoutes
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {
    documentInfo(to, from, next, (to.meta.hasOwnProperty('documentInfo'))? to.meta.documentInfo :  {})

    if (to.meta.hasOwnProperty('guards')) {
        checkRouteGuards(to, from, next, to.meta.guards)
    } else {
        next();
    }
});

export default router

