import Vue from 'vue'
import boot from 'devegram-vue2-booting-system/src/index'
import router from '@/system-services/router/index'
// import "./registerServiceWorker";
Vue.config.productionTip = false;

import App from './system-services/views/App'
import {globalData, afterBoot} from "@/app/booting/boot-flow";

boot({app: Vue, afterBoot, globalData}).then( (config) => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
})

