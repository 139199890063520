export const langs = [
  {
    lang: 'en',
    translation: {
      label: 'label',
      create: 'create',
      list : 'list',
      this_field_is_required: 'this field is required',
      fields_do_not_match_the_required_criteria: 'please check form fields',
      deleted: 'deleted',
      edit: 'edit',
      cancel: 'cancel',
      required: 'required',
      needed: 'needed',
      missed: 'missed',
      delete: 'delete'
    }
  }
]

export default langs
